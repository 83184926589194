import './index.less';

import Vue from "vue";
import { Component } from 'vue-property-decorator';
import { Table } from "@/app/components/index"
import {
    zsChildGuarderInfoService,
    IChildGuarderInfo,
} from "@/app/apiServices/zsChildGuarderInfo/public-api";
import { getStepsIcon } from "@/core/utils";
import { thisCity_stepsList } from "@/core/utils/enum/thisCity";
import storage from '@/core/utils/storage';

@Component({
    components: { Table }
})
export default class familyMemberInformationTS extends Vue {

    guarderList: IChildGuarderInfo[] = [];
    columns = [
        {
            type: "index",
            label: "序号",
            width: 50,
            align: "center",
        },
        {
            prop: "relationship",
            label: "称谓",
            width: 90,
            align: "center",
        },
        {
            prop: "name",
            label: "姓名",
            width: 100,
            align: "center",
        },
        {
            prop: "cardType",
            label: "证件类型",
            align: "center",
        },
        {
            prop: "cardNO",
            label: "证件号码",
            align: "center",
        },
        {
            prop: "telephone",
            label: "手机",
            align: "center",
            width: 110,
        },
        {
            prop: "defaultGuarder",
            label: "是否是主要联系人",
            align: "center",
            slot: "defaultGuarder",
            width: 90,
        },
        {
            prop: "option",
            label: "操作",
            align: "center",
            slot: "option",
            width: 110,
        },
    ]
    stepsList = thisCity_stepsList;
    activeSteps = 1;

    getStepsIcon(index: number) {
        return getStepsIcon(index, this.activeSteps);
    }

    nextOnClick() {
        if (this.guarderList.length === 0) {
            this.$message.warning('至少有一位家庭成员');
            return;
        }
        if (this.guarderList.every((u) => u.defaultGuarder == false)) {
            this.$message.warning('至少有一位家庭成员为监护人');
            return;
        }
        this.$router.push(`/thisCity/materialInformation`);
    }

    setDefaultGuarderOnClick(val: IChildGuarderInfo) {
        this.$dialog
            .confirm({
                title: "提示",
                message: `确认将${val.name}设置为主要联系监护人吗？`,
            })
            .then(() => {
                zsChildGuarderInfoService.setDefaultGuarder(val.id).then(() => {
                    this.$toast({
                        message: "设置成功",
                        icon: 'success',
                        onClose: () => {
                            this.viewLoad();
                        },
                    });
                });
            }).catch(() => {
                //on close     
            });
    }

    updateOnClick(val: IChildGuarderInfo) {
        this.$store.dispatch('ACTION_FamilyMember', val);
        this.$router.push(`/thisCity/familyMemberInformation/updateFamilyMembers`);
    }
    addOnClick() {
        storage.DELETE_FamilyMember();
        this.$router.push(`/thisCity/familyMemberInformation/addFamilyMembers`);
    }
    deleteOnClick(val: IChildGuarderInfo) {
        this.$dialog.confirm({
            title: '确认删除',
            message: `确认将${val.name}从家庭成员删除吗？`,
        })
            .then(() => {
                zsChildGuarderInfoService.delete(val.id).then((res: any) => {
                    if (res === val.id) {
                        this.$toast('删除成功');
                        this.viewLoad();
                    }
                });
            })
            .catch(() => {
                //on close     
            });
    }

    async viewLoad() {
        const { childId }: any = storage.GET_RegistrationInfo();
        this.guarderList = await zsChildGuarderInfoService.getGuarderList(childId);
        this.$store.dispatch('ACTION_GuarderList', this.guarderList);
    }
    mounted(): void {
        this.viewLoad();
    }
}